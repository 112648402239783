import { Badge } from "@mantine/core";
import moment from "moment";

import "moment/locale/hu";
import "moment/locale/ro";
import images from "../reusable/LanguangeSelector/images/images";
const isMobile = () => {
	if (
		window.innerWidth < 768 ||
		navigator.platform.indexOf("iPhone") !== -1 ||
		navigator.platform.indexOf("iPad") !== -1 ||
		navigator.platform.indexOf("Android") !== -1
	) {
		return true;
	} else {
		return false;
	}
};
const isMobilePrinter = () => {
	return navigator.userAgent.match(/Android/i);
};
function isOldFormat(str) {
	const pattern = /^\d{2}\.\d{2}\.\d{4}\/\d{2}:\d{2}:\d{2}$/;
	return pattern.test(str);
}

const getHourFormat = (date) => {
	//with moment
	const dateI = moment(date).format("HH:mm");
	return dateI;
};

export const getDateFormat = (date, simple, language = "ro") => {
	// const newDate = new Date(date);
	// check if date is in format 18.11.2022/14:59:32
	if (date === undefined) return "";
	try {
		if (isOldFormat(date)) {
			const dateI = moment(date, "DD.MM.YYYY/HH:mm")
				.locale(language)
				.format("dddd DD MMMM YYYY HH:mm");
			return dateI;
		}
		const dateI = moment(date)
			.locale(language)
			.format(!simple ? "dddd DD MMMM YYYY HH:mm" : "ddd, DD MMM YYYY");
		if (dateI === "Invalid date") {
			const dateI = moment(date, "DD.MM.YYYY/HH:mm")
				.locale(language)
				.format("dddd DD MMMM YYYY HH:mm");
			return dateI;
		}
		return dateI;
	} catch (e) {
		return undefined;
	}
};

const statusDecriptor = (status) => {
	switch (status) {
		case "CT":
			return "CANTARIT";
		case "CA":
			return "CALCAT";
		case "FI":
			return "FINALIZAT";
		case "PL":
			return "PLASAT";
		case "PR":
			return "PRELUAT";
		case "LI":
			return "LIVRAT";
		case "PLI":
			return "LIVRAT PARTIAL";
		case "BN":
			return "BONAT";
		case "SP":
			return "SPALAT";
		default:
			return "Necunoscut";
	}
};

function sendUrlToPrint(url) {
	var beforeUrl = "intent:";
	var afterUrl = "#Intent;";
	// Intent call with component
	afterUrl += "component=ru.a402d.rawbtprinter.activity.PrintDownloadActivity;";
	afterUrl += "package=ru.a402d.rawbtprinter;end;";
	document.location = beforeUrl + encodeURI(url) + afterUrl;
	return false;
}

const getBadge = (status) => {
	switch (status) {
		case "Traseu":
			return "success";
		case "Sediu":
			return "primary";
		default:
			return "warning";
	}
};

const getRowColor = (status) => {
	switch (status) {
		case "Traseu":
			return { _classes: "table-delivery" };
		case "Sediu":
			return { _classes: "table-not-delivery" };
		default:
			return { _classes: "table-pickup-point" };
	}
};
export const getRowColorMobileApp = (orderSource) => {
	switch (orderSource) {
		case "MobileApp":
			return { _classes: "table-order-mobile" };

		default:
			return { _classes: "" };
	}
};

const getNewBadge = (delivery, isPickupPoint = false, pickupPointName = "", t) => {
	switch (delivery) {
		case "Traseu":
			return (
				<Badge variant="gradient" gradient={{ from: "green", to: "yellow" }}>
					{t("general.route")}
				</Badge>
			);
		case "Sediu": {
			if (isPickupPoint) {
				return (
					<Badge variant="filled" color="yellow">
						{pickupPointName}
					</Badge>
				);
			} else
				return (
					<Badge variant="gradient" gradient={{ from: "blue", to: "violet" }}>
						{t("general.inHouse")}
					</Badge>
				);
		}
		default:
			return (
				<Badge variant="filled" color="yellow">
					{pickupPointName}
				</Badge>
			);
	}
};

const getDriverName = (driverId, drivers) => {
	if (drivers && drivers?.length > 0) {
		const driver = drivers.find((driver) => driver._id === driverId);
		if (driver) {
			return driver.name;
		}
	}
	return "";
};
export const calculateTotalSqm = (products, roundUnderOneSqm) => {
	const calculus = products.reduce((acc, product) => {
		if (product.carpet) {
			const totalSqmCalc = (product?.width ?? 0) * (product?.length ?? 0);
			const totalSqm = roundUnderOneSqm && totalSqmCalc < 1 ? 1 : totalSqmCalc;
			return acc + totalSqm;
		} else {
			return acc;
		}
	}, 0);
	return calculus.toFixed(2);
};

const transformFieldsToReadableTable = (fields, drivers, language, roundUnderOneSqm) => {
	// extract key value pairs from fields and convert them based on key
	const transformedFields = fields.map((field) => {
		const keyList = Object.keys(field);
		keyList.map((key) => {
			switch (key) {
				case "createdAt":
					field[key] = getDateFormat(field[key], true, language) || "";
					break;
				case "pickUpDateAproximate":
					field[key] = getDateFormat(field[key], true, language) || "";
					break;
				case "pickUpDate":
					field[key] = getDateFormat(field[key], false, language) || "";
					break;
				case "cashingDate":
					field[key] = getDateFormat(field[key], false, language) || "";
					break;
				case "deliveryDate":
					field[key] = getDateFormat(field[key], false, language) || "";
					break;
				case "cashInDate":
					field[key] = getDateFormat(field[key], false, language) || "";
					break;
				case "aproximateDelivery":
					field["aproximateDeliveryDate"] = field[key];
					field[key] = getDateFormat(field[key], true, language) || "";
					break;
				case "cashTaken":
					field[key] = field[key] ? "DA" : "NU";
					break;
				case "pickupDriverId":
					field["driver"] = getDriverName(field[key], drivers) || "Neasignat";
					field["driverPickupName"] = getDriverName(field[key], drivers) || "Neasignat";
					break;
				case "deliveryDriverId":
					field["driver"] = getDriverName(field[key], drivers) || "Neasignat";
					field["driverDeliveryName"] = getDriverName(field[key], drivers) || "Neasignat";
					break;
				case "delivery":
					field["deliveryBoolean"] = field[key];

					field[key] = field[key] ? "Traseu" : "Sediu";
					//if is pickup point, then location is always pickupName key
					field["location"] = field["isPickupPoint"] ? field["pickupPointName"] : field[key];

					break;
				default:
					field[key] = field[key] ? field[key] : "";
					break;
			}

			return field;
		});
		field["totalSqm"] = calculateTotalSqm(field?.products || [], roundUnderOneSqm);
		field["aproximateDeliveryDate"] = field["aproximateDeliveryDate"] || "";
		field["aproximateDelivery"] = field["aproximateDelivery"] || "";
		field["clientAddress"] = field["clientAddress"] || "";
		field["clientName"] = field["clientName"] || "";
		field["clientPhone"] = field["clientPhone"] || "";
		field["driver"] = field["driver"] || "";
		field["driverPickupName"] = field["driverPickupName"] || "";
		field["driverDeliveryName"] = field["driverDeliveryName"] || "";
		field["createdAt"] = field["createdAt"] || "";
		field["deliveryDate"] = field["deliveryDate"] || "";
		field["cashingDate"] = field["cashingDate"] || "";
		field["cashInDate"] = field["cashInDate"] || "";
		field["notes"] = field["notes"] || "";
		field["totalPrice"] = field["restToMinimumOrder"] ? field["minimumOrder"] : field["totalPrice"];
		field["driverNotes"] = field["driverNotes"] || "";
		// if there is secondary Ticket, edit ticketNumber by adding it
		if (field["secondaryTickets"] && field["secondaryTickets"].length > 0) {
			field["ticketNumber"] = field["secondaryTickets"].join(", ") + ", " + field["ticketNumber"];
		} else {
			field["ticketNumber"] = field["ticketNumber"] || "";
		}

		return field;
	});
	return transformedFields;
};

const disablePastDate = () => {
	const today = new Date();
	const dd = String(today.getDate()).padStart(2, "0");
	const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
	const yyyy = today.getFullYear();
	return yyyy + "-" + mm + "-" + dd;
};
// definition = {
//   1: 'comanda plasata sediu',
//   2: 'comanda plasata traseu',
//   3: 'comenzi masurate',
//   4: 'comenzi spalate',
//   5: 'comenzi finalizate',
//   6: 'comenzi de incasat',
//   7: 'comenzi incomplete',
//   8: 'comenzi incasate',
//   }
const statusDecriptorForPickupPoint = (t, status) => {
	switch (status) {
		case 0.5:
			return "Comanda de ridicat din punct de lucru";
		case 2:
			return "Comanda ajunsa in sediu";
		case 3:
			return t("editOrder.orderStatusMeasured");
		case 4:
			return t("editOrder.orderStatusWashed");
		case 5:
			return t("editOrder.orderStatusToDelivered");
		case 6:
			return t("editOrder.orderStatusToCashing");
		case 10:
			return "Comanda pregatita de livrare la punctul de lucru";
		default:
			return t("editOrder.orderStatusUnknown");
	}
};
const statusDecriptorForTable = (t, status, delivery, pickUpDateAproximate, pickUpDate) => {
	let statusTwo = "";
	if (delivery) {
		// if pickupDate approximate is set, then the order is confirmed
		if (pickUpDate) {
			statusTwo = t("editOrder.orderStatusPickedUp");
		} else if (pickUpDateAproximate) {
			statusTwo = t("editOrder.orderStatusConfirmedPickup");
		} else {
			statusTwo = t("editOrder.orderStatusToConfirm");
		}
	} else {
		statusTwo = t("editOrder.orderStatusCollected");
	}
	switch (status) {
		case 0.5:
			return "Comanda de ridicat din punct de lucru";
		case 1:
			return delivery ? t("editOrder.orderStatusToPickup") : t("editOrder.orderStatusCollected");
		case 2:
			return statusTwo;
		case 3:
			return t("editOrder.orderStatusMeasured");
		case 4:
			return t("editOrder.orderStatusWashed");
		case 5:
			return t("editOrder.orderStatusToDelivered");
		case 6:
			return t("editOrder.orderStatusToCashing");
		case 7:
			return t("editOrder.orderStatusIncomplete");
		case 8:
			return t("editOrder.orderStatusFinished");
		case 10:
			return "Comanda pregatita de livrare la punctul de lucru";
		default:
			return t("editOrder.orderStatusUnknown");
	}
};
const languageData = [
	{ label: "RO", image: images.romanian32 },
	{ label: "HU", image: images.hungarian32 },
	{ label: "EN", image: images.english32 },
	{ label: "PL", image: images.polish32 },
	{ label: "NL", image: images.dutch32 },
	{ label: "FR", image: images.french32 },
	{ label: "DE", image: images.german32 },
	{ label: "TR", image: images.turkish32 },
	{ label: "BG", image: images.bulgarian32 },
	{ label: "SK", image: images.slovak32 },
];
const returnCorrectLanguage = (languageIncoming) => {
	const language = localStorage.getItem("language");
	//@ts-ignore
	const browserLanguage = navigator.language || navigator.userLanguage;
	if (languageIncoming) {
		if (languageIncoming && !language) {
			//@ts-ignore
			return (
				languageData.find(
					//@ts-ignore
					(item) => item.label === languageIncoming.toUpperCase()
				) || languageData[0]
			);
		} else if (language) {
			//@ts-ignore
			return languageData.find((item) => item.label === language.toUpperCase());
		} else if (browserLanguage) {
			//@ts-ignore
			return (
				languageData.find((item) => item.label === browserLanguage.toUpperCase()) || languageData[0]
			);
		}
	} else {
		return languageData[0];
	}
};

const getAddressReadable = (addressDetailed, simpleAddress) => {
	if (addressDetailed) {
		return (
			<>
				{addressDetailed.city && !addressDetailed?.sector ? ` ${addressDetailed.city} ` : ""}
				{addressDetailed.city && addressDetailed?.sector ? ` ${addressDetailed.sector} ` : ""}
				{addressDetailed.streetName ? `- ${addressDetailed?.streetName}` : ""}
				{addressDetailed.streetNumber ? ` - nr. ${addressDetailed.streetNumber}` : ""}
				{addressDetailed.neighborhood ? `- Cartier: ${addressDetailed.neighborhood}` : ""}
			</>
		);
	} else {
		return simpleAddress;
	}
};
const generateCarpetId = () => {
	// 8 random numbers anc characters
	const randomString = Math.random().toString(36).substring(2, 10);
	return randomString.toLocaleUpperCase();
};

export const getPaymentStatus = (order, language) => {
	if (!order?.orderPaid) {
		return "Comanda neplatita";
	}
	const partialPaid = order?.amountPaid < order?.totalPrice;

	const items = [
		`Platit: <strong>${partialPaid ? "Partial" : "Intreg"}</strong>`,
		`Suma: <strong>${order?.amountPaid || ""}</strong>`,
		`Mod: <strong>${order?.paidByCreditCard ? "Card" : "Cash"}</strong>`,
		`Data: <strong>${getDateFormat(order?.orderPaidDate, false, language)}</strong>`,
		`BF: <strong>${order?.fiscalTicket || "N/A"}</strong>`,
	];

	// For HTML display with line breaks
	// return items.join("<br>");
	// Or, for HTML display with divs (each item on a new line)
	return items.map((item) => `<div>${item}</div>`).join("");
};

export const getTimeAndHoursFromUnixDate = (unix, timezone) => {
	// Using moment-timezone to handle timezone by region/city string, accounting for daylight saving time automatically
	const formattedTime = moment.unix(unix).format("HH:mm");
	return formattedTime;
};

export const getTimeAndHoursFromUnixDateFromJobs = (orderId, jobs) => {
	const job = jobs.find((job) => job.orderId === orderId);
	if (job) {
		return getTimeAndHoursFromUnixDate(job?.arrival);
	}
	return "00:00";
};

export const transformFromSecondsToHours = (seconds) => {
	//return time in minutes
	const minutes = seconds / 60;
	return minutes.toFixed(2);
};

export const findAndTransformFromSecondsToOursFromJobs = (orderId, jobs, key) => {
	const job = jobs.find((job) => job.orderId === orderId);
	if (job && job[key] && key) {
		return transformFromSecondsToHours(job[key]);
	}
	return "0:00";
};

export const transformFromMetersToKm = (meters) => {
	// Convert meters to km
	const km = meters / 1000;
	//if km is less than 0.01, return 0.01
	if (km < 0.01) {
		return "0.01";
	}
	return km.toFixed(2);
};

export const findAndTransformFromMetersToKmFromJobs = (orderId, jobs) => {
	const job = jobs.find((job) => job.orderId === orderId);
	if (job) {
		return transformFromMetersToKm(job.distance);
	}
	return "0";
};

const utils = {
	getPaymentStatus,
	generateCarpetId,
	isMobile,
	getDateFormat,
	statusDecriptor,
	sendUrlToPrint,
	isMobilePrinter,
	transformFieldsToReadableTable,
	getBadge,
	disablePastDate,
	getHourFormat,
	getDriverName,
	getNewBadge,
	statusDecriptorForTable,
	statusDecriptorForPickupPoint,
	languageData,
	returnCorrectLanguage,
	getAddressReadable,
	getRowColor,
};
export default utils;
