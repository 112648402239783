import { Flex, TextInput } from "@mantine/core";
import googleApi from "@services/google";
import { useCallback, useEffect, useRef } from "react";
import EqualGapContainer from "./EqualGapContainer";
import SectionLabel from "./SectionLabel";

interface IOrganizationData {
	fillData: (name: string, value: any) => void;
	organizationCountry: string;
}

export default function OrganizationData({ fillData, organizationCountry }: IOrganizationData) {
	//------------------------------------------------------ google maps

	const mapApiJs = "https://maps.googleapis.com/maps/api/js";
	// @ts-ignore
	const options = (selectedCountryCode: string) => {
		return { componentRestrictions: { country: selectedCountryCode.toLowerCase() || "ro" } };
	};

	const searchInput = useRef(null);

	// init gmap script
	const initMapScript = () => {
		// if script already loaded
		if (window.google) {
			return Promise.resolve();
		}
		const src = `${mapApiJs}?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&v=weekly`;
		return googleApi.loadAsyncScript(src);
	};

	// do something on address change
	// @ts-ignore
	const onChangeAddress = (autocomplete) => {
		const place = autocomplete.getPlace();
		fillData("organizationAddress", googleApi.extractAddress(place).plain());
		// organizationAddressDetailed: { ...googleApi.extractAddress(place) },
		// 	//@ts-ignore
		// 	organizationOrgLan: googleApi.extractAddress(place).lat,
		// 	//@ts-ignore
		// 	organizationOrgLong: googleApi.extractAddress(place).lng,
		fillData("organizationOrgLan", googleApi.extractAddress(place).lat);
		fillData("organizationOrgLong", googleApi.extractAddress(place).lng);
		fillData("organizationAddressDetailed", { ...googleApi.extractAddress(place) });
	};

	// init autocomplete
	const initAutocomplete = useCallback(() => {
		if (!searchInput.current) return;
		const input = document.getElementById("nf-address");
		const autocomplete = new window.google.maps.places.Autocomplete(
			input as HTMLInputElement,
			options(organizationCountry)
		);
		autocomplete.setFields(["address_component", "geometry"]);
		autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
	}, [searchInput, organizationCountry]);

	// load map script after mounted
	useEffect(() => {
		initMapScript().then(() => initAutocomplete());
	}, [initAutocomplete, organizationCountry]);
	//------------------------------------------------------

	return (
		<div>
			<EqualGapContainer gap="26px">
				<SectionLabel label="Organization data" alert={"Please add organization name and email, mandatory"} />

				<EqualGapContainer gap="16px">
					<Flex gap={16}>
						<TextInput
							onChange={(e) => fillData(e.target.name, e.target.value)}
							name="organizationName"
							w="100%"
							size="sm"
							placeholder="Short name"
							required
							label="Organization name"
						/>
						<TextInput
							onChange={(e) => fillData(e.target.name, e.target.value)}
							name="organizationEmail"
							w="100%"
							size="sm"
							required
							label="Organization email"
						/>
					</Flex>
					<Flex gap={16}>
						<TextInput
							onChange={(e) => fillData(e.target.name, e.target.value)}
							name="organizationNameEntity"
							w="100%"
							size="sm"
							placeholder="Your organization's full name"
							required
							label="Organization name entity"
						/>
						<TextInput
							onChange={(e) => fillData(e.target.name, e.target.value)}
							name="organizationPhone"
							w="100%"
							size="sm"
							required
							label="Organization phone number"
						/>
					</Flex>
					{/* <TextInput
						onChange={(e) => fillData(e.target.name, e.target.value)}
						name="organizationAddress"
						w="100%"
						size="sm"
						required
						label="Organization address"
					/> */}
					<input
						style={{
							width: "100%",
							height: "40px",
							borderRadius: "5px",
							border: "1px solid #ccc",
							paddingLeft: "10px",
						}}
						ref={searchInput}
						type="text"
						id="nf-address"
						name="nf-address"
					// onChange={(e) => setOrUnsetAddress(e.target.value)}
					/>

					<Flex gap={16}>
						<TextInput
							onChange={(e) => fillData(e.target.name, e.target.value)}
							name="organizationCif"
							w="100%"
							size="sm"
							required
							label="CIF"
						/>
						<TextInput
							onChange={(e) => fillData(e.target.name, e.target.value)}
							name="organizationRegCom"
							w="100%"
							size="sm"
							required
							placeholder="Unique registration code"
							label="URC"
						/>
					</Flex>
				</EqualGapContainer>
			</EqualGapContainer>
		</div>
	);
}
