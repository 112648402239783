import { Alert, createStyles } from "@mantine/core";
import { accentLightRGBA, accentRGBA } from "../Register";

const useStyles = createStyles(() => ({
	h4Style: {
		borderBottom: `0.5px solid ${accentLightRGBA}`,
		color: accentRGBA,
		width: "100%",
		fontWeight: 600,
		fontSize: 20,
		lineHeight: "125%",
		paddingBottom: 8,
		display: "flex",
		justifyContent: "space-between",
	},
	divHeader: {
		display: "flex",
		flexDirection: "column",
	}
}));

export default function SectionLabel({
	label,
	rightSide,
	alert
}: {
	label: string;
	rightSide?: React.ReactNode;
	alert?: string;
}) {
	const { classes } = useStyles();
	return (
		<div className={classes.divHeader}>
			<div className={classes.h4Style}>
				<h4>{label}</h4>
				{rightSide}

			</div>
			{alert && (
				<Alert color="red" title="Required" >
					{alert}
				</Alert>
			)}

		</div>

	);
}
