import axios, { AxiosInstance } from "axios";

let instance: AxiosInstance | null = null;

const createInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_COVOARE_CURATE_API,
    headers: {
      "Content-Type": "application/json",
    },
  });
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        //click on logout with id logOutButton
        document.getElementById("logOutButton")?.click();
      }
      return error;
    }
  );
  return instance;
};

const getInstance = () => {
  const formLocalStorage = localStorage.getItem("persist:form");
  const formParsed = JSON.parse(formLocalStorage ? formLocalStorage : "{}");

  const organizationId = formParsed.organizationSelectedId;
  const token = formParsed.token;

  if (!instance) {
    instance = createInstance();
  }

  if (organizationId && token) {
    //extract only the id from the organization
    const organizationIdParsed = JSON.parse(organizationId);
    const tokenParsed = JSON.parse(token);

    instance.defaults.headers.common["auth-token"] = tokenParsed;
    instance.defaults.headers.common[
      "organization-id"
    ] = `${organizationIdParsed}`;
  }

  return instance;
};
export const setHeaders = (organizationId?: string, authToken?: string) => {
  let instance = getInstance();
  if (organizationId) {
    instance.defaults.headers.common["organization-id"] = organizationId;
  }
  if (authToken) {
    instance.defaults.headers.common["auth-token"] = authToken;
  }
};

const fetchClient = () => {
  // Create instance
  let instance = getInstance();
  return instance;
};

export default fetchClient();
