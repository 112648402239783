import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import BulgarianJson from "./translations/Bulgarian.json";
import DutchJson from "./translations/Dutch.json";
import EnglishJson from "./translations/English.json";
import FrenchJson from "./translations/French.json";
import GermanJson from "./translations/German.json";
import HungarianJson from "./translations/Hungarian.json";
import PolishJson from "./translations/Polish.json";
import RomanianJson from "./translations/Romanian.json";
import SlovakJson from "./translations/Slovak.json";
import TurkishJson from "./translations/Turkish.json";
// Translation resources
const resources = {
	en: {
		translation: {
			navbar: {
				home: "Home",
				contact: "Contact",
				features: "Features",
				videoTutorials: "Video Tutorials",
				aboutUs: "About Us",
				reviews: "Reviews",
			},
			footer: {
				home: "Home",
				contact: "Contact",
				checkOrder: "Check Order",
				termsAndConditions: "Terms and Conditions",
				privacyPolicy: "Privacy Policy",
			},
			user: EnglishJson.user,
			sidebar: EnglishJson.sidebar,
			settings: EnglishJson.settings,
			general: EnglishJson.general,
			products: EnglishJson.products,
			registry: EnglishJson.registry,
			carpetNewOrder: EnglishJson.carpetNewOrder,
			confirmationModal: EnglishJson.confirmationModal,
			ordersTable: EnglishJson.ordersTable,
			ordersBreadcrumbs: EnglishJson.ordersBreadcrumbs,
			pdfElements: EnglishJson.pdfElements,
			editOrder: EnglishJson.editOrder,
			statistics: EnglishJson.statistics,
			termsAndConditions: EnglishJson.termsAndConditions,
			notifications: EnglishJson.notifications,
			qrLabels: EnglishJson.qrLabels,
			clothes: EnglishJson.clothes,
			horeca: EnglishJson.horeca
		},
	},
	ro: {
		translation: {
			navbar: {
				home: "Acasă",
				contact: "Contact",
				features: "Funcționalități",
				videoTutorials: "Tutoriale video",
				aboutUs: "Despre noi",
				reviews: "Recenzii",
			},
			footer: {
				home: "Acasă",
				contact: "Contact",
				checkOrder: "Verifică comanda",
				termsAndConditions: "Termeni și condiții",
				privacyPolicy: "Politica de confidențialitate",
			},
			user: RomanianJson.user,
			sidebar: RomanianJson.sidebar,
			settings: RomanianJson.settings,
			general: RomanianJson.general,
			products: RomanianJson.products,
			registry: RomanianJson.registry,
			carpetNewOrder: RomanianJson.carpetNewOrder,
			confirmationModal: RomanianJson.confirmationModal,
			ordersTable: RomanianJson.ordersTable,
			ordersBreadcrumbs: RomanianJson.ordersBreadcrumbs,
			pdfElements: RomanianJson.pdfElements,
			editOrder: RomanianJson.editOrder,
			statistics: RomanianJson.statistics,
			termsAndConditions: RomanianJson.termsAndConditions,
			notifications: RomanianJson.notifications,
			qrLabels: RomanianJson.qrLabels,
			clothes: RomanianJson.clothes,
			horeca: RomanianJson.horeca
		},
	},
	hu: {
		translation: {
			footer: {
				home: "Kezdőlap",
				contact: "Kapcsolat",
				checkOrder: "Rendelés ellenőrzése",
				termsAndConditions: "Felhasználási feltételek",
				privacyPolicy: "Adatvédelmi nyilatkozat",
			},
			navbar: {
				home: "Kezdőlap",
				contact: "Kapcsolat",
				features: "Funkciók",
				videoTutorials: "Videó oktatóanyagok",
				aboutUs: "Rólunk",
				reviews: "Vélemények",
			},
			user: HungarianJson.user,
			sidebar: HungarianJson.sidebar,
			settings: HungarianJson.settings,
			general: HungarianJson.general,
			products: HungarianJson.products,
			registry: HungarianJson.registry,
			carpetNewOrder: HungarianJson.carpetNewOrder,
			confirmationModal: HungarianJson.confirmationModal,
			ordersTable: HungarianJson.ordersTable,
			ordersBreadcrumbs: HungarianJson.ordersBreadcrumbs,
			pdfElements: HungarianJson.pdfElements,
			editOrder: HungarianJson.editOrder,
			statistics: HungarianJson.statistics,
			termsAndConditions: HungarianJson.termsAndConditions,
			notifications: HungarianJson.notifications,
			qrLabels: HungarianJson.qrLabels,
			clothes: HungarianJson.clothes,
			horeca: HungarianJson.horeca
		},
	},
	pl: {
		translation: {
			navbar: {
				home: "Strona główna",
				contact: "Kontakt",
				features: "Funkcje",
				videoTutorials: "Poradniki wideo",
				aboutUs: "O nas",
				reviews: "Recenzje",
			},
			footer: {
				home: "Strona główna",
				contact: "Kontakt",
				checkOrder: "Sprawdź zamówienie",
				termsAndConditions: "Regulamin",
				privacyPolicy: "Polityka prywatności",
			},
			sidebar: PolishJson.sidebar,
			settings: PolishJson.settings,
			general: PolishJson.general,
			products: PolishJson.products,
			registry: PolishJson.registry,
			carpetNewOrder: PolishJson.carpetNewOrder,
			confirmationModal: PolishJson.confirmationModal,
			ordersTable: PolishJson.ordersTable,
			ordersBreadcrumbs: PolishJson.ordersBreadcrumbs,
			pdfElements: PolishJson.pdfElements,
			editOrder: PolishJson.editOrder,
			statistics: PolishJson.statistics,
			termsAndConditions: PolishJson.termsAndConditions,
			notifications: PolishJson.notifications,
			qrLabels: PolishJson.qrLabels,
			clothes: PolishJson.clothes,
			horeca: PolishJson.horeca
		},
	},
	nl: {
		translation: {
			navbar: {
				home: "Thuis",
				contact: "Contact",
				features: "Functies",
				videoTutorials: "Video Tutorials",
				aboutUs: "Over Ons",
				reviews: "Recensies",
			},
			footer: {
				home: "Thuis",
				contact: "Contact",
				checkOrder: "Bestelling controleren",
				termsAndConditions: "Algemene voorwaarden",
				privacyPolicy: "Privacybeleid",
			},
			sidebar: DutchJson.sidebar,
			settings: DutchJson.settings,
			general: DutchJson.general,
			products: DutchJson.products,
			registry: DutchJson.registry,
			carpetNewOrder: DutchJson.carpetNewOrder,
			confirmationModal: DutchJson.confirmationModal,
			ordersTable: DutchJson.ordersTable,
			ordersBreadcrumbs: DutchJson.ordersBreadcrumbs,
			pdfElements: DutchJson.pdfElements,
			editOrder: DutchJson.editOrder,
			statistics: DutchJson.statistics,
			termsAndConditions: DutchJson.termsAndConditions,
			notifications: DutchJson.notifications,
			qrLabels: DutchJson.qrLabels,
			clothes: DutchJson.clothes,
			horeca: DutchJson.horeca
		},
	},
	fr: {
		translation: {
			navbar: {
				home: "Accueil",
				contact: "Contact",
				features: "Fonctionnalités",
				videoTutorials: "Tutoriels vidéo",
				aboutUs: "À propos de nous",
				reviews: "Avis",
			},
			footer: {
				home: "Accueil",
				contact: "Contact",
				checkOrder: "Vérifier la commande",
				termsAndConditions: "Conditions générales",
				privacyPolicy: "Politique de confidentialité",
			},
			sidebar: FrenchJson.sidebar,
			settings: FrenchJson.settings,
			general: FrenchJson.general,
			products: FrenchJson.products,
			registry: FrenchJson.registry,
			carpetNewOrder: FrenchJson.carpetNewOrder,
			confirmationModal: FrenchJson.confirmationModal,
			ordersTable: FrenchJson.ordersTable,
			ordersBreadcrumbs: FrenchJson.ordersBreadcrumbs,
			pdfElements: FrenchJson.pdfElements,
			editOrder: FrenchJson.editOrder,
			statistics: FrenchJson.statistics,
			termsAndConditions: FrenchJson.termsAndConditions,
			notifications: FrenchJson.notifications,
			qrLabels: FrenchJson.qrLabels,
			clothes: FrenchJson.clothes,
			horeca: FrenchJson.horeca
		},
	},
	de: {
		translation: {
			navbar: {
				home: "Startseite",
				contact: "Kontakt",
				features: "Funktionen",
				videoTutorials: "Videoanleitungen",
				aboutUs: "Über uns",
				reviews: "Bewertungen",
			},
			footer: {
				home: "Startseite",
				contact: "Kontakt",
				checkOrder: "Bestellung überprüfen",
				termsAndConditions: "Nutzungsbedingungen",
				privacyPolicy: "Datenschutzrichtlinien",
			},
			sidebar: GermanJson.sidebar,
			settings: GermanJson.settings,
			general: GermanJson.general,
			products: GermanJson.products,
			registry: GermanJson.registry,
			carpetNewOrder: GermanJson.carpetNewOrder,
			confirmationModal: GermanJson.confirmationModal,
			ordersTable: GermanJson.ordersTable,
			ordersBreadcrumbs: GermanJson.ordersBreadcrumbs,
			pdfElements: GermanJson.pdfElements,
			editOrder: GermanJson.editOrder,
			statistics: GermanJson.statistics,
			termsAndConditions: GermanJson.termsAndConditions,
			notifications: GermanJson.notifications,
			qrLabels: GermanJson.qrLabels,
			clothes: GermanJson.clothes,
			horeca: GermanJson.horeca
		},
	},
	tr: {
		translation: {
			navbar: {
				home: "Ana Sayfa",
				contact: "İletişim",
				features: "Özellikler",
				videoTutorials: "Video Eğitimler",
				aboutUs: "Hakkımızda",
				reviews: "Yorumlar",
			},
			footer: {
				home: "Ana Sayfa",
				contact: "İletişim",
				checkOrder: "Sipariş Kontrolü",
				termsAndConditions: "Şartlar ve Koşullar",
				privacyPolicy: "Gizlilik Politikası",
			},
			sidebar: TurkishJson.sidebar,
			settings: TurkishJson.settings,
			general: TurkishJson.general,
			products: TurkishJson.products,
			registry: TurkishJson.registry,
			carpetNewOrder: TurkishJson.carpetNewOrder,
			confirmationModal: TurkishJson.confirmationModal,
			ordersTable: TurkishJson.ordersTable,
			ordersBreadcrumbs: TurkishJson.ordersBreadcrumbs,
			pdfElements: TurkishJson.pdfElements,
			editOrder: TurkishJson.editOrder,
			statistics: TurkishJson.statistics,
			termsAndConditions: TurkishJson.termsAndConditions,
			notifications: TurkishJson.notifications,
			qrLabels: TurkishJson.qrLabels,
			clothes: TurkishJson.clothes,
			horeca: TurkishJson.horeca
		},
	},
	bg: {
		translation: {
			navbar: {
				home: "Начало",
				contact: "Контакт",
				features: "Характеристики",
				videoTutorials: "Видео Уроци",
				aboutUs: "Относно Нас",
				reviews: "Отзиви",
			},
			footer: {
				home: "Начало",
				contact: "Контакт",
				checkOrder: "Проверка на Поръчка",
				termsAndConditions: "Общи Условия",
				privacyPolicy: "Политика за поверителност",
			},
			sidebar: BulgarianJson.sidebar,
			settings: BulgarianJson.settings,
			general: BulgarianJson.general,
			products: BulgarianJson.products,
			registry: BulgarianJson.registry,
			carpetNewOrder: BulgarianJson.carpetNewOrder,
			confirmationModal: BulgarianJson.confirmationModal,
			ordersTable: BulgarianJson.ordersTable,
			ordersBreadcrumbs: BulgarianJson.ordersBreadcrumbs,
			pdfElements: BulgarianJson.pdfElements,
			editOrder: BulgarianJson.editOrder,
			statistics: BulgarianJson.statistics,
			termsAndConditions: BulgarianJson.termsAndConditions,
			notifications: BulgarianJson.notifications,
			qrLabels: BulgarianJson.qrLabels,
			clothes: BulgarianJson.clothes,
			horeca: BulgarianJson.horeca
		},
	},
	sk: {
		translation: {
			navbar: {
				home: "Domov",
				contact: "Kontakt",
				features: "Funkcie",
				videoTutorials: "Videonávody",
				aboutUs: "O nás",
				reviews: "Recenzie",
			},
			footer: {
				home: "Domov",
				contact: "Kontakt",
				checkOrder: "Skontrolovať objednávku",
				termsAndConditions: "Všeobecné obchodné podmienky",
				privacyPolicy: "Zásady ochrany osobných údajov",
			},
			sidebar: SlovakJson.sidebar,
			settings: SlovakJson.settings,
			general: SlovakJson.general,
			products: SlovakJson.products,
			registry: SlovakJson.registry,
			carpetNewOrder: SlovakJson.carpetNewOrder,
			confirmationModal: SlovakJson.confirmationModal,
			ordersTable: SlovakJson.ordersTable,
			ordersBreadcrumbs: SlovakJson.ordersBreadcrumbs,
			pdfElements: SlovakJson.pdfElements,
			editOrder: SlovakJson.editOrder,
			statistics: SlovakJson.statistics,
			termsAndConditions: SlovakJson.termsAndConditions,
			notifications: SlovakJson.notifications,
			qrLabels: SlovakJson.qrLabels,
			clothes: SlovakJson.clothes,
			horeca: SlovakJson.horeca
		},
	},
};

i18n.use(initReactI18next).init({
	resources,
	lng: "en", // Set the default language
	fallbackLng: "en", // Fallback language if translation is missing
	interpolation: {
		escapeValue: false, // React already escapes values by default
	},
});

export default i18n;
