import registerApi from "@services/registry";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

export const useGetOpenAndClosedRegistry = (delivery: boolean) => {
	//@ts-ignore
	const module = useSelector((state) => state.form.module);
	//@ts-ignore
	const pickupPointId = useSelector((state) => state.form.pickupPointId);

	return useQuery(
		["open-close-registry" + module, delivery],
		() => {
			return registerApi.getActiveOrClosedRegistry(delivery, module, pickupPointId);
		},
		{ retry: false }
	);
};
