const defaultState = {
	sidebarShow: "responsive",
	tutorial: "notStarted",
	organizationList: [],
	organizationSelectedId: "",
	organizationSelected: {},
	isSoftwareAdmin: false,
	loginDone: false,
	isSuperAdmin: false,
	token: "",
	pickupPointList: [],
	pickupPointSelectedId: "",
	pickupPointSelected: {},
	userData: {},
	module: "covoare",
	isAdmin: false,
	isDriver: false,
	drivers: [],
	modulesPaid: [],
	editPageAlert: true,
	hasDelivery: true,
	isInMainPickupPoint: true,
	pickupPointId: "",
	pickupPointName: "",
	clientsSelectedForCampaign: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultState, { type, ...rest }) {
	switch (type) {
		case "set":
			return { ...state, ...rest };
		default:
			return state;
	}
}
