import { ActionIcon, Badge, Flex, HoverCard, createStyles } from "@mantine/core";
import { IconDotsVertical, IconUserCircle } from "@tabler/icons";
import { IRegisteredUser, accentRGBA, stroke } from "../Register";
import EqualGapContainer from "./EqualGapContainer";

const useStyles = createStyles(() => ({
	textName: {
		color: accentRGBA,
		fontWeight: 600,
		fontSize: 16,
		lineHeight: "150%",
		paddingBottom: 5,
		borderBottom: `1px solid ${accentRGBA}`,
		textAlign: "center",
		marginBottom: 0,
	},
	textPhone: {
		fontWeight: 600,
		fontSize: 16,
		lineHeight: "150%",
		paddingBottom: 5,
		borderBottom: `1px solid ${stroke}`,
		textAlign: "center",
		marginBottom: 0,
	},
	cardContainer: {
		backgroundColor: "white",
		padding: "16px 32px",
		borderRadius: 5,
		minHeight: "220px",
		minWidth: "160px",
		boxShadow: "1px 2px 6px 0px rgba(0,0,0,0.05)",
		position: "relative",
	},
	moreMenuIcon: {
		position: "absolute",
		top: 0,
		right: 0,
		margin: "8px 8px 0 0",
	},
	moreMenuActionButton: {
		width: "100%",
		padding: "5px 10px",
		fontSize: "14px",
		background: "none",
		border: "none",
		textAlign: "left",
		"&:hover": {
			background: "rgba(117, 117, 117, 0.05)",
		},
	},
}));

interface ISUserCard {
	item: IRegisteredUser;
	deleteUser: (i: number) => void;
	openEditModal: (i: number) => void;
	i: number;
}

export default function UserCard({
	item,
	deleteUser,
	openEditModal,
	i,
}: ISUserCard) {
	const { classes } = useStyles();
	const { name, phone, role } = item;
	return (
		<div className={classes.cardContainer}>
			<HoverCard>
				<HoverCard.Target>
					<div className={classes.moreMenuIcon}>
						<ActionIcon variant="transparent">
							<IconDotsVertical />
						</ActionIcon>
					</div>
				</HoverCard.Target>
				<HoverCard.Dropdown p={0}>
					<div>
						<button
							onClick={() => openEditModal(i)}
							className={classes.moreMenuActionButton}
							type="button"
						>
							Edit
						</button>
						<button
							onClick={() => deleteUser(i)}
							className={classes.moreMenuActionButton}
							type="button"
						>
							Delete
						</button>
					</div>
				</HoverCard.Dropdown>
			</HoverCard>
			<div>
				<EqualGapContainer gap="16px">
					<Flex justify="center">
						<IconUserCircle size={60} stroke={2} color={accentRGBA} />
					</Flex>
					<p className={classes.textName}>{name}</p>
					<p className={classes.textPhone}>{phone}</p>
					<Flex justify="center">
						<Badge w="fit-content">{role}</Badge>
					</Flex>
				</EqualGapContainer>
			</div>
		</div>
	);
}
