import axiosInstance from "./axiosInstance";

const getActiveRegistry = async (delivery, module) => {
	const response = await axiosInstance
		.get(`/v1/registry?delivery=${delivery}&module=${module}`)
		.then((data) => {
			if (!(data.status === 200)) {
				throw new Error(data.status + " Failed Get registry ");
			} else {
				return data.data;
			}
		});
	return response;
};

const getClosedRegistryList = async (module) => {
	const response = await axiosInstance.get(`/v1/registry-history?module=${module}`).then((data) => {
		if (!(data.status === 200)) {
			throw new Error(data.status + " Failed Get registry ");
		} else {
			return data.data;
		}
	});
	return response;
};
export const updateRegistry = async (registryId, registerData) => {
	const response = await axiosInstance
		.patch(`/v1/registry/${registryId}`, {
			...registerData,
		})
		.then((data) => {
			if (!(data.status === 201)) {
				throw new Error(data.status + " Failed Update registry ");
			} else {
				return data.data;
			}
		});
	return response;
};

export const createRegistry = async (registerData) => {
	const response = await axiosInstance
		.post(`/v1/registry`, {
			...registerData,
		})
		.then((data) => {
			if (!(data.status === 201)) {
				throw new Error(data.status + " Failed Create registry ");
			} else {
				return data.data;
			}
		});
	return response;
};

const getActiveOrClosedRegistry = async (delivery, module, pickupPointId = "") => {
	const response = await axiosInstance
		.get(`/v1/registry-open?delivery=${delivery}&module=${module}&pickupPointId=${pickupPointId}`)
		.then((data) => {
			if (!(data.status === 200)) {
				throw new Error(data.status + " Failed Get registry ");
			} else {
				return data.data;
			}
		});
	return response;
};

const getAllRegistry = async (module) => {
	const response = await axiosInstance.get(`/v1/registry-all?module=${module}`).then((data) => {
		if (!(data.status === 200)) {
			throw new Error(data.status + " Failed Get registry ");
		} else {
			return data.data;
		}
	});
	return response;
};

const getLastEntryInRegistry = async (registryIdList, module) => {
	const response = await axiosInstance
		.get(`/v1/registry-last-entry?registryIdList=${registryIdList}&module=${module}`)
		.then((data) => {
			if (!(data.status === 200)) {
				throw new Error(data.status + " Failed Get registry ");
			} else {
				return data.data;
			}
		});
	return response;
};

const checkIfTicketExists = async (registryId, ticketNumber) => {
	const response = await axiosInstance
		.post(`/v1/registry-check`, {
			registryId,
			ticketNumber: Number(ticketNumber),
		})
		.then((data) => {
			if (!(data.status === 200)) {
				throw new Error(data.status + " Failed Get registry ");
			} else {
				return data.data;
			}
		});
	return response;
};

const registerApi = {
	getActiveRegistry,
	updateRegistry,
	createRegistry,
	getClosedRegistryList,
	getActiveOrClosedRegistry,
	getLastEntryInRegistry,
	checkIfTicketExists,
	getAllRegistry,
};

export default registerApi;
