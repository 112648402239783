import { hideNotification } from "@mantine/notifications";
import {
	showNotificationError,
	showNotificationLoading,
	showNotificationSuccess,
} from "@reusable/Notifications/notificationSystem";
import { deleteOrganization } from "@services/organization";
import { useMutation } from "@tanstack/react-query";

export const useDeleteOrganization = () => {
	const randomId = Math.random().toString(36).substring(7);
	return useMutation<any, Error, {
		removeKey: string;
		organizationId: string;
	}>(
		//@ts-ignore
		({ removeKey, organizationId }) => deleteOrganization(organizationId, removeKey),
		{
			onSuccess: async () => {
				showNotificationSuccess(" Organizatia a fost stearsa cu succes");
				hideNotification(randomId);
			},
			onError: async (error: string) => {
				showNotificationError("Eroare la stergerea organizatiei");
				hideNotification(randomId);
			},
			onMutate: async () => {
				showNotificationLoading("Stergere organizatie in progress", randomId);
			},
		},
		{ useErrorBoundary: true }
	);
};

export default useDeleteOrganization;
