import useDeleteOrganization from "@hooks/organization/useDeleteOrganization";
import useNotifyOrganizationAccounts from "@hooks/organization/useNotifiyOrganizationAccounts";
import useRegisterNewOrganization from "@hooks/registration/useRegisterNewOrganization";
import { UserType } from "@hooks/users/useEditUser";
import { Button, Divider, Flex, Modal, TextInput, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconCheck, IconCloudDownload, IconMailFast, IconTrash } from "@tabler/icons";
import { useEffect, useState } from "react";
import { OrganizationInterface } from "types";
import AddUserModal from "./components/AddUserModal";
import AdministratorData from "./components/AdministratorData";
import EqualGapContainer from "./components/EqualGapContainer";
import OrganizationData from "./components/OrganizationData";
import OrganizationSettings from "./components/OrganizationSettings";
import UsersList from "./components/UsersList";

export interface IRegisteredUser {
	name: string;
	role: UserType;
	isAdmin: boolean;
	isSuperAdmin: boolean;
	isDriver: boolean;
	phone: string;
	password: string;
	username?: string;
}

export interface IEditUser extends IRegisteredUser {
	index: number | null;
}

export interface IDataToRegister {
	organizationToCreate: Partial<OrganizationInterface>;
	userList: IRegisteredUser[];
}

const initialValues = {
	organizationToCreate: {
		organizationName: "",
		organizationNameEntity: "",
		organizationPhone: "",
		organizationAddress: "",
		organizationCif: "",
		organizationRegCom: "",
		organizationEmail: "",
		administratorName: "",
		administratorBi: "",
		organizationCountry: "",
		organizationCurrency: "",
		organizationLanguage: "",
		organizationPrefix: "",
		organizationTimezone: "",
		modules: [],
	},
	userList: [],
};

export const modalInitialValues = {
	name: "",
	role: UserType.Driver,
	isAdmin: false,
	isSuperAdmin: false,
	isDriver: false,
	phone: "",
	index: null,
	password: "",
};

export const accent = "#228BE6";
export const accentRGBA = "rgba(32, 139, 230, 100)";
export const accentLightRGBA = "rgba(32, 139, 230, 0.3)";
export const bg = "#F7FAFD";
export const stroke = "#ABB0B5";

const useStyles = createStyles((theme) => ({
	centerContainer: {
		width: "100%",
		display: "grid",
		placeContent: "center",
		padding: "2rem",
		backgroundColor: bg,
		minHeight: "100vh",
	},
	flexContainer: {
		display: "flex",
		gap: "32px",
		[`@media (max-width: ${theme.breakpoints.md}px)`]: {
			flexDirection: "column",
		},
	},
}));

export default function Register() {
	const { classes } = useStyles();
	const [opened, { open, close }] = useDisclosure(false);
	const [openedRegKeyModal, { open: openRegKeyModal, close: closeRegKeyModal }] =
		useDisclosure(false);
	const [dataToRegister, setDataToRegister] = useState<IDataToRegister>(initialValues);
	const [modalValues, setModalValues] = useState<IEditUser>(modalInitialValues);
	const [isEditMode, setIsEditMode] = useState(false);
	const [registrationKey, setRegistrationKey] = useState("");
	const [pdfPassword, setPdfPassword] = useState("");
	const [organizationIdCreated, setOrganizationIdCreated] = useState("");
	const { mutate: mutateCreateOrganization, isSuccess, data, isLoading } = useRegisterNewOrganization();
	const {
		mutate: mutateDeleteOrganization,
		isSuccess: isDeleteSuccess,
		isLoading: isDeleteLoading,

	} = useDeleteOrganization();
	const {
		mutate: mutateNotifyOrganizationAccounts,
		isSuccess: isNotifySuccess,
		isLoading: isNotifyLoading,

	} = useNotifyOrganizationAccounts();


	useEffect(() => {
		if (isDeleteSuccess) {
			window.location.reload();
		}

	}, [isDeleteSuccess]);

	useEffect(() => {
		if (isSuccess && data) {
			setOrganizationIdCreated(data.organization?._id);
		}
	}, [isSuccess, data]);


	const fillData = (name: string, value: string) => {
		setDataToRegister((prev) => ({
			...prev,
			organizationToCreate: {
				...prev.organizationToCreate,
				[name]: value,
			},
		}));
	};

	const selectModules = (modules: string[]) => {
		setDataToRegister((prev) => {
			return {
				...prev,
				organizationToCreate: {
					...prev.organizationToCreate,
					modules: [...modules],
				},
			};
		});
	};

	const addOrEditUser = (user: IRegisteredUser, edit: boolean, editIndex: number | null) => {
		if (edit && editIndex !== null && editIndex >= 0) {
			setDataToRegister((prev) => {
				const newUsersList = [...prev.userList];
				newUsersList[editIndex] = user;
				return {
					...prev,
					userList: newUsersList,
				};
			});
		} else {
			setDataToRegister((prev) => ({
				...prev,
				userList: [...prev.userList, user],
			}));
		}
	};

	const deleteUser = (i: number) => {
		const newUsersList = dataToRegister.userList.filter((_, index) => index !== i);
		setDataToRegister((prev) => ({
			...prev,
			userList: newUsersList,
		}));
	};

	const closeModal = () => {
		if (isEditMode) {
			setIsEditMode((prev) => !prev);
		}
		close();
	};

	const openEditModal = (i: number) => {
		setModalValues({
			...dataToRegister.userList[i],
			isAdmin: false,
			isSuperAdmin: false,
			isDriver: false,
			index: i,
		});
		setIsEditMode((prev) => !prev);
		open();
	};

	const isSubmitDataDisabled = () => {
		// dataToRegister.userList.length === 0 || !dataToRegister.organizationToCreate.organizationName
		const hasUserList = dataToRegister.userList.length > 0;
		const hasAtLeastOneSuperAdmin = dataToRegister.userList.some((user) => user.isSuperAdmin);
		const hasAtLeastOneDriver = dataToRegister.userList.some((user) => user.isDriver);
		const hasOrganizationName = dataToRegister.organizationToCreate.organizationName;
		const hasAtLeastOneModule = dataToRegister.organizationToCreate.modules && dataToRegister.organizationToCreate.modules.length > 0;
		return !hasUserList || !hasAtLeastOneSuperAdmin || !hasAtLeastOneDriver || !hasOrganizationName || !hasAtLeastOneModule;
	}

	return (
		<>
			<Modal
				title="Register new organization"
				opened={openedRegKeyModal}
				onClose={() => closeRegKeyModal()}
				size="lg"
			>
				<EqualGapContainer gap="16px">
					<TextInput
						label="Registration key"
						onChange={(e) => setRegistrationKey(e.target.value)}
					/>
					<TextInput
						label="Pdf password"
						onChange={(e) => setPdfPassword(e.target.value)}
					/>
					{
						data?.pdfUrl && (
							<>
								<Divider />
								<Flex gap="16px" justify="flex-end">
									<Button
										onClick={() => {
											window.open(data?.pdfUrl, '_blank');
										}}
										disabled={!data?.pdfUrl}
										aria-label="Download PDF"
										variant="outline"
										leftIcon={<IconCloudDownload />}
									>
										Download PDF
									</Button>
									<Button color="blue.9"
										leftIcon={isNotifySuccess ? <IconCheck /> : <IconMailFast />}
										disabled={!data?.pdfUrl || !organizationIdCreated}
										onClick={() => {
											mutateNotifyOrganizationAccounts({ pdfUrl: data?.pdfUrl, organizationId: organizationIdCreated });
										}}
										loading={isNotifyLoading}

									>
										Send PDF via email
									</Button>
									<Button
										color="red.9"
										leftIcon={<IconTrash />}
										loading={isDeleteLoading}
										onClick={() => {
											mutateDeleteOrganization({ organizationId: organizationIdCreated, removeKey: registrationKey });
										}}
									>
										Remove organzation
									</Button>
								</Flex>
								<Divider />
							</>

						)
					}
					<Flex gap="16px" justify="flex-end">
						<Button
							disabled={isLoading}
							variant="subtle"
							onClick={() => {
								setRegistrationKey("");
								closeRegKeyModal();
							}}
						>
							Cancel
						</Button>
						<Button
							loading={isLoading}
							disabled={!registrationKey || !pdfPassword || isSuccess}
							onClick={() => { mutateCreateOrganization({ data: dataToRegister, registrationKey, pdfPassword, }); }}
						>
							Register organization
						</Button>

					</Flex>

				</EqualGapContainer>
			</Modal>
			<AddUserModal
				isEditMode={isEditMode}
				addOrEditUser={addOrEditUser}
				opened={opened}
				close={closeModal}
				modalValues={modalValues}
				setModalValues={setModalValues}
			/>
			<div className={classes.centerContainer}>
				<div className={classes.flexContainer}>
					<div style={{ width: "100%" }}>
						<EqualGapContainer gap="32px">
							<OrganizationData
								organizationCountry={dataToRegister.organizationToCreate.organizationCountry || ""}
								fillData={fillData}
							/>
							<OrganizationSettings fillData={fillData} />
							<AdministratorData selectModules={selectModules} fillData={fillData} />
							<Button onClick={() => openRegKeyModal()} w="100%"
								disabled={isSubmitDataDisabled()}
							>
								Submit data
							</Button>
						</EqualGapContainer>
					</div>
					<div style={{ width: "100%" }}>
						<UsersList
							openEditModal={openEditModal}
							deleteUser={deleteUser}
							usersList={dataToRegister.userList}
							open={open}
						/>
					</div>
				</div>
			</div>
		</>
	);
}
