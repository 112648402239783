const loadAsyncScript = (src) => {
	return new Promise((resolve) => {
		const script = document.createElement("script");
		Object.assign(script, {
			type: "text/javascript",
			async: true,
			src,
		});
		script.addEventListener("load", () => resolve(script));
		document.head.appendChild(script);
	});
};

const extractAddress = (place) => {
	const address = {
		city: "",
		state: "",
		zip: "",
		country: "",
		lat: "",
		lng: "",
		streetNumber: "",
		streetName: "",
		place_id: "",
		neighborhood: "",
		county: "",
		sector: "",
		plain() {
			if (this.streetName && this.streetNumber) {
				return `${this.streetName} nr. ${this.streetNumber}, ${
					this.sector ? this.sector : this.city
				}`;
			} else if (this.streetName) {
				return `${this.streetName}, ${this.city}`;
			} else if (this.neighborhood) {
				return `Cartier ${this.neighborhood}, ${this.city}`;
			} else {
				return `${this.city}`;
			}
		},
	};

	if (!Array.isArray(place?.address_components)) {
		return address;
	}
	address.lat = place.geometry.location.lat();
	address.lng = place.geometry.location.lng();
	place.address_components.forEach((component) => {
		const types = component.types;
		const value = component.long_name;
		const valueShort = component.short_name;
		if (types.includes("street_number")) {
			address.streetNumber = value;
		}
		if (types.includes("route")) {
			address.streetName = value;
		}
		if (types.includes("locality")) {
			address.city = value;
		}

		if (types.includes("administrative_area_level_2")) {
			address.state = value;
		}

		if (types.includes("postal_code")) {
			address.zip = value;
		}

		if (types.includes("country")) {
			address.country = value;
		}
		if (types.includes("place_id")) {
			address.place_id = value;
		}
		if (types.includes("neighborhood")) {
			address.neighborhood = value;
		}
		if (types.includes("administrative_area_level_1")) {
			address.county = valueShort;
		}
		if (types.includes("sublocality_level_1")) {
			address.sector = valueShort;
		}
	});
	return address;
};
function transformAddressObject(addressObj) {
	const address = {
		streetNumber: "",
		streetName: "",
		city: "",
		state: "",
		country: "",
		zip: "",
	};
	addressObj.forEach((obj) => {
		if (obj.types.includes("street_number")) {
			address.streetNumber = obj.short_name;
		} else if (obj.types.includes("route")) {
			address.streetName = obj.short_name;
		} else if (obj.types.includes("locality")) {
			address.city = obj.long_name;
		} else if (obj.types.includes("administrative_area_level_1")) {
			address.state = obj.long_name;
		} else if (obj.types.includes("postal_code")) {
			address.zip = obj.short_name;
		} else if (obj.types.includes("country")) {
			address.country = obj.long_name;
		}
	});
	return address;
}

const googleAPI = {
	loadAsyncScript,
	extractAddress,
	transformAddressObject,
};
export default googleAPI;
