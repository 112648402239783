import { Flex, createStyles } from "@mantine/core";
import { IconPlus, IconUsers } from "@tabler/icons";
import { IRegisteredUser, accentLightRGBA, accentRGBA } from "../Register";
import EqualGapContainer from "./EqualGapContainer";
import SectionLabel from "./SectionLabel";
import UserCard from "./UserCard";

interface IUsersList {
	usersList: IRegisteredUser[];
	open: () => void;
	deleteUser: (i: number) => void;
	openEditModal: (i: number) => void;
}

const useStyles = createStyles((theme, params, getRef) => ({
	usersCount: {
		color: accentRGBA,
		fontWeight: 600,
		fontSize: 20,
		lineHeight: "125%",
	},
	overflowContainer: {
		width: "100%",
		display: "grid",
		gridTemplateColumns: "repeat(3, 1fr)",
		gap: "26px",
		[`@media (max-width: ${theme.breakpoints.xs}px)`]: {
			gridTemplateColumns: "repeat(auto-fill, 160px)",
			gap: "16px",
			placeContent: "center",
		},
	},
	iconStyle: {
		color: accentLightRGBA,
		ref: getRef("iconStyle"),
		transition: "0.3s",
	},
	ghostCardContainer: {
		background: "none",
		padding: "16px 32px",
		borderRadius: 5,
		minWidth: "160px",
		minHeight: "220px",
		border: `2px dashed ${accentLightRGBA}`,
		display: "grid",
		placeContent: "center",
		transition: "0.3s",
		"&:hover": {
			border: `2px dashed ${accentRGBA}`,
		},
		[`&:hover .${getRef("iconStyle")}`]: {
			color: accentRGBA,
		},
	},
}));

export default function UsersList({
	open,
	usersList,
	deleteUser,
	openEditModal,
}: IUsersList) {
	const { classes } = useStyles();
	return (
		<div>
			<EqualGapContainer gap="26px">
				<SectionLabel
					alert={"You must add at least one super-admin and one driver"}
					rightSide={
						<Flex gap={5}>
							<h4 className={classes.usersCount}>{usersList.length}</h4>
							<IconUsers color={accentRGBA} size={24} stroke={2} />
						</Flex>
					}
					label="Registered users"
				/>
				<div className={classes.overflowContainer}>
					{usersList?.map((item, i) => (
						<UserCard
							openEditModal={openEditModal}
							deleteUser={deleteUser}
							item={item}
							i={i}
							key={i}
						/>
					))}
					<button onClick={() => open()} className={classes.ghostCardContainer}>
						<IconPlus size={32} className={classes.iconStyle} />
					</button>
				</div>
			</EqualGapContainer>
		</div>
	);
}
