import { hideNotification } from "@mantine/notifications";
import {
	showNotificationError,
	showNotificationLoading,
	showNotificationSuccess,
} from "@reusable/Notifications/notificationSystem";
import { editUser } from "@services/user";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export enum UserType {
	Driver = 'sofer',
	Sediu = 'sediu',
	PickupPoint = 'pickup-point',
	SuperAdmin = 'super-admin',
	SoftwareAdmin = 'software-admin',
	SMS = 'sms-user'
}

export interface UserInterface {
	_id?: string;
	username: string;
	name: string;
	role: UserType;
	password: string;
	createdAt?: Date;
	updatedAt?: Date;
	isAdmin: boolean;
	isSuperAdmin: boolean;
	isDriver: boolean;
	phoneNumber: string;
	organizationId: Array<string>;
	pickupPointId: Array<string>;
	fbToken?: string;
}
function useEditUser() {
	const randomId = Math.random().toString(36).substring(7);
	const { t } = useTranslation();

	return useMutation<any, Error, { userId: string, userData: Partial<UserInterface> }>(
		//@ts-ignore
		({ userId, userData }) => editUser(userId, userData),
		{
			onSuccess: async () => {
				showNotificationSuccess(t("user.editSuccess"));
				hideNotification(randomId);
			},
			onError: async (error: string) => {
				showNotificationError(t("user.editError"));
				hideNotification(randomId);
			},
			onMutate: async () => {
				showNotificationLoading(t("user.editing"), randomId);
			},
		},
		{ useErrorBoundary: true }
	);
}

export default useEditUser;
