import { useGetOpenAndClosedRegistry } from "@hooks/registry/useGetOpenAndClosedRegistry";
import { ActionIcon } from "@mantine/core";
import { RegistryInterface } from "@views/registry/NewRegistryTable";
import { memo, useEffect, useState } from "react";
import { ColorSwatch } from "tabler-icons-react";

interface ActionIconColorSwatchProps {
	registryIdOrder: string;
	size?: "xs" | "sm" | "md" | "lg" | "xl";
	delivery: boolean;
}

const ActionIconColorSwatch = memo(
	({ registryIdOrder, size = "xl", delivery }: ActionIconColorSwatchProps) => {
		const { data } = useGetOpenAndClosedRegistry(delivery);
		const [color, setColor] = useState("grey.9");

		useEffect(() => {
			if (data) {
				const registry = data.find(
					(registry: RegistryInterface) => registry?._id === registryIdOrder
				);
				if (registry) {
					setColor(registry?.colorTickets);
				} else {
					setColor("#fff");
				}
			}
		}, [data, registryIdOrder]);
		return (
			<>
				{registryIdOrder ? (
					<ActionIcon
						key={"nextTicket" + registryIdOrder}
						color={"gray.5"}
						variant={"filled"}
						size={size}
						style={{
							border: "1px solid black",
						}}
					>
						<ColorSwatch color={color} size="27" />
					</ActionIcon>
				) : (
					"N/A"
				)}
			</>
		);
	},
	(prevProps, nextProps) => {
		return (
			prevProps.registryIdOrder === nextProps.registryIdOrder &&
			prevProps.delivery === nextProps.delivery
		);
	}
);

export default ActionIconColorSwatch;
