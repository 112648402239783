import axiosInstance from "./axiosInstance";

const getDrivers = async () => {
	const response = await axiosInstance.get(`/v1/user/drivers`).then((data) => {
		if (!(data.status === 200)) {
			throw new Error(data.status + " Failed Get Clients ");
		} else {
			return data.data;
		}
	});
	return response;
};

const acceptTermsAndConditions = async (userId, acceptedTypes) => {
	const response = await axiosInstance
		.post(`/v1/user/terms/${userId}`, acceptedTypes)
		.then((data) => {
			if (!(data.status === 200)) {
				throw new Error(data.status + " Failed Accept Terms And Conditions ");
			} else {
				return data.data;
			}
		});
	return response;
};

export const deactivateUser = async (userId, shouldBeActive) => {
	const response = await axiosInstance
		.post(`/v1/user/deactivate/${userId}`, { shouldBeActive })
		.then((data) => {
			if (data.status !== 200) {
				throw new Error(data.status + " Failed to deactivate user");
			} else {
				return data.data;
			}
		});
	return response;
};

export const editUser = async (userId, userData) => {
	const response = await axiosInstance
		.patch(`/v1/user/edit/${userId}`, { ...userData })
		.then((data) => {
			if (data.status !== 200) {
				throw new Error(data.status + " Failed to edit user");
			} else {
				return data.data;
			}
		});
	return response;
};

export const createUser = async (userData) => {
	const response = await axiosInstance.post(`/v1/user/create`, { ...userData }).then((data) => {
		if (data.status !== 200) {
			throw new Error(data.status + " Failed to create user");
		} else {
			return data.data;
		}
	});
	return response;
};

const usersApi = {
	getDrivers,
	acceptTermsAndConditions,
};

export default usersApi;
