import { Avatar, Flex, Group, Select, Text } from "@mantine/core";
import {
	companyLanguage,
	country,
	countryPrefix,
	currency,
	timezone,
} from "@reusable/LanguangeSelector/TimezoneAndName";
import { forwardRef } from "react";
import EqualGapContainer from "./EqualGapContainer";
import SectionLabel from "./SectionLabel";

interface IOrganizationSettings {
	fillData: (name: string, value: string) => void;
}

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
	image: string;
	label: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
	({ image, label, ...others }: ItemProps, ref) => (
		<div ref={ref} {...others}>
			<Group noWrap>
				<Avatar size="xs" src={image} />
				<div>
					<Text size="sm">{label}</Text>
				</div>
			</Group>
		</div>
	)
);

export default function OrganizationSettings({ fillData }: IOrganizationSettings) {
	return (
		<div>
			<EqualGapContainer gap="26px">
				<SectionLabel label="Organization settings" />
				<EqualGapContainer gap="16px">
					<Flex gap={16}>
						<Select
							onChange={(e) => fillData("organizationCountry", e as string)}
							name="organizationCountry"
							w="100%"
							searchable
							data={country}
							size="sm"
							required
							label="Country code"
							itemComponent={SelectItem}
						/>
						<Select
							onChange={(e) => fillData("organizationCurrency", e as string)}
							name="organizationCurrency"
							w="100%"
							searchable
							data={currency}
							size="sm"
							required
							itemComponent={SelectItem}
							label="Currency"
						/>
					</Flex>
					<Flex gap={16}>
						<Select
							onChange={(e) => fillData("organizationLanguage", e as string)}
							name="organizationLanguage"
							w="100%"
							searchable
							data={companyLanguage}
							size="sm"
							required
							itemComponent={SelectItem}
							label="Language"
						/>
						<Select
							onChange={(e) => fillData("organizationPrefix", e as string)}
							name="organizationPrefix"
							w="100%"
							searchable
							data={countryPrefix}
							size="sm"
							required
							itemComponent={SelectItem}
							label="Phone prefix"
						/>
					</Flex>
					<Select
						onChange={(e) => fillData("organizationTimezone", e as string)}
						name="organizationTimezone"
						w="100%"
						searchable
						data={timezone}
						size="sm"
						required
						itemComponent={SelectItem}
						label="Organization address"
					/>
				</EqualGapContainer>
			</EqualGapContainer>
		</div>
	);
}
