import { IDataToRegister } from "@views/pages/register/Register";
import axios from "axios";
import { OrganizationInterface } from "types";
import axiosInstance from "./axiosInstance";

export const createNewOrganization = async (data: IDataToRegister, registrationKey: string, organizationId: string, token: string,
	pdfPassword: string,
) => {
	const response = await axios
		.post(
			`${process.env.REACT_APP_COVOARE_CURATE_API}/v1/organization`,
			{
				...data,
				registrationKey,
				pdfPassword,
			},
			{
				headers: {
					"Content-Type": "application/json",
					"organization-id": organizationId,
					"auth-token": token,
				},
			}
		)
		.then((res) => {
			if (!res.data) {
				throw new Error(`${res.status} Failed to create organization`);
			} else {
				return res.data;
			}
		})
		.catch((error) => {
			throw new Error(error.message);
		});
	return response;
};

export const updateOrganization = async (
	organizationId: string,
	token: string,
	data: OrganizationInterface
) => {
	const response = await axios
		.patch(
			`${process.env.REACT_APP_COVOARE_CURATE_API}/v1/organization/${organizationId}`,
			{ ...data },
			{
				headers: {
					"Content-Type": "application/json",
					"auth-token": token,
					"organization-id": organizationId,
				},
			}
		)
		.then((response) => {
			if (!response.data) {
				throw new Error(response.status + " Failed Get Clients ");
			} else {
				return response.data;
			}
		});
	return response;
};

export const deleteOrganization = async (organizationId: string, removeKey: string) => {
	const response = await axiosInstance(
		`${process.env.REACT_APP_COVOARE_CURATE_API}/v1/organization/${organizationId}`,
		{
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				removeKey
			}
		}
	).then((response) => {
		if (!response.data) {
			throw new Error(response.status + " Failed Get Clients ");
		} else {
			return response.data;
		}
	});
	return response;
}

export const notifyOrganizationNewAccountsCreated = async (organizationId: string, pdfUrl: string) => {
	//post request to notify organization
	const response = await axiosInstance(
		`${process.env.REACT_APP_COVOARE_CURATE_API}/v1/organization/notify/${organizationId}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				pdfUrl
			}
		}
	).then((response) => {
		if (!response.data) {
			throw new Error(response.status + " Failed Get Clients ");
		} else {
			return response.data;
		}
	}
	);

	return response;
}
const organizationApi = {
	updateOrganization,
	createNewOrganization,
};

export default organizationApi;
