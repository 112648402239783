import { CSSProperties } from "react";

interface IEqualGapContainer {
	w?: CSSProperties["width"];
	gap?: string;
	justify?: CSSProperties["justifyContent"];
	mb?: string;
	children: React.ReactNode;
}

export default function EqualGapContainer({
	w = "100%",
	gap = "45px",
	justify = "flex-start",
	mb = "0",
	children,
}: IEqualGapContainer) {
	return (
		<div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: gap,
					justifyContent: justify,
					marginBottom: mb,
					width: w,
				}}
			>
				{children}
			</div>
		</div>
	);
}
