import { Badge, Center, createStyles, Group, Text, UnstyledButton } from "@mantine/core";
import { SpotlightActionProps } from "@mantine/spotlight";
import ActionIconColorSwatch from "@views/edit/ActionIconColorSwatch";

const useStyles = createStyles((theme) => ({
	action: {
		position: "relative",
		display: "block",
		width: "100%",
		padding: "10px 20px",
		borderRadius: theme.radius.sm,
		...theme.fn.hover({
			backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[1],
		}),

		"&[data-hovered]": {
			backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[1],
		},
	},
}));

function SearchComponentItem({
	action,
	styles,
	classNames,
	hovered,
	onTrigger,
	...others
}: SpotlightActionProps) {
	//@ts-ignore
	const { classes } = useStyles(null, {
		styles,
		classNames,
		name: "Spotlight",
	});
	return (
		<UnstyledButton
			className={classes.action}
			data-hovered={hovered || undefined}
			tabIndex={-1}
			//@ts-ignore

			onMouseDown={(event) => event.stopPropagation()}
			onClick={onTrigger}
			{...others}
		>
			<Group noWrap>
				<Center>{action.icon}</Center>

				<div style={{ flex: 1 }}>
					<Text>{action.title}</Text>

					{action.description && (
						<Text color="dimmed" size="xs">
							{action.description}
						</Text>
					)}
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						alignItems: "center",
						gap: 5,
					}}
				>
					{action.status && <Badge>{action.status}</Badge>}
					{action.ticketNumber && (
						<Badge w="100%" color={"grape"}>
							{action.ticketNumber}
						</Badge>
					)}
					{action.registryId && action.isMobile && (
						<ActionIconColorSwatch registryIdOrder={action.registryId || ""} delivery size="lg" />
					)}
				</div>
				{action.registryId && !action.isMobile && (
					<ActionIconColorSwatch registryIdOrder={action.registryId || ""} delivery size="lg" />
				)}
			</Group>
		</UnstyledButton>
	);
}

export default SearchComponentItem;
