// Copyright 2023 Alphadoc
// Unauthorized copying of this content is strictly prohibited

import { showNotification } from "@mantine/notifications";
import { IconX } from "@tabler/icons";
import { CircleCheck } from "tabler-icons-react";

export const showNotificationSuccess = (title: string, message = "") =>
	showNotification({
		title,
		message: "",
		icon: <CircleCheck size="1.1rem" />,
		color: "green",
		autoClose: 1000,
		style: { zIndex: 9999999999 },
	});

export const showNotificationLoading = (title: string, id?: string, message = "") =>
	showNotification({
		title,
		message: "",
		icon: <IconX size="1.1rem" />,
		color: "blue",
		id,
		style: { zIndex: 9999999999 },
	});

export const showNotificationError = (title: string, message?: string) =>
	showNotification({
		title,
		message: message || "",
		icon: <IconX size="1.1rem" />,
		color: "red",
		autoClose: 1000,
		style: { zIndex: 9999999999 },
	});
