import { hideNotification } from "@mantine/notifications";
import {
	showNotificationError,
	showNotificationLoading,
	showNotificationSuccess,
} from "@reusable/Notifications/notificationSystem";
import { createNewOrganization } from "@services/organization";
import { useMutation } from "@tanstack/react-query";
import { IDataToRegister } from "@views/pages/register/Register";
import { useSelector } from "react-redux";

interface IRegistrationData {
	data: IDataToRegister;
	registrationKey: string;
	pdfPassword: string;
}

export default function useRegisterNewOrganization() {
	const randomId = Math.random().toString(36).substring(7);
	const selectedOrganizationId = useSelector(
		(state: any) => state.form.organizationSelectedId
	);
	const token = useSelector((state: any) => state.form.token);

	return useMutation<any, Error, IRegistrationData>(
		// @ts-ignore
		({ data, registrationKey, pdfPassword }) => createNewOrganization(data, registrationKey, selectedOrganizationId, token, pdfPassword),
		{
			onSuccess: async () => {
				showNotificationSuccess("Organization registered successfully");
				hideNotification(randomId);
			},
			onError: async (error: string) => {
				showNotificationError("Error while registering organization and users");
				hideNotification(randomId);
			},
			onMutate: async () => {
				showNotificationLoading("Registering organization in progress", randomId);
			},
		},
		{ useErrorBoundary: true }
	);
}
