import { hideNotification } from "@mantine/notifications";
import {
	showNotificationError,
	showNotificationLoading,
	showNotificationSuccess,
} from "@reusable/Notifications/notificationSystem";
import { notifyOrganizationNewAccountsCreated } from "@services/organization";
import { useMutation } from "@tanstack/react-query";

export const useNotifyOrganizationAccounts = () => {
	const randomId = Math.random().toString(36).substring(7);
	return useMutation<any, Error, {
		pdfUrl: string;
		organizationId: string
	}>(
		//@ts-ignore
		({ pdfUrl, organizationId }) => notifyOrganizationNewAccountsCreated(organizationId, pdfUrl),
		{
			onSuccess: async () => {
				showNotificationSuccess("Notification sent successfully");
				hideNotification(randomId);
			},
			onError: async (error: string) => {
				showNotificationError("Error while sending notification");
				hideNotification(randomId);
			},
			onMutate: async () => {
				showNotificationLoading("Sending notification in progress", randomId);
			},
		},
		{ useErrorBoundary: true }
	);
};

export default useNotifyOrganizationAccounts;
